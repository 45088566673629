import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgLaunchCover from "../../assets/images/journey/launch/launch-cover.png"

const LaunchPage = () => (
  <Layout lang="ar">
    <SEO title="Launch" lang="ar" />
    <main>
      <div className="uk-cover-container" uk-height-viewport="offset-top: true; offset-bottom: 40" style={{borderBottom: "5px solid #fff"}}>
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgLaunchCover} alt="" style={{transform: "translate(50%,-50%)"}} data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div>
                  <h2 className="relative">
                  خطة مرحلة الإطلاق                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  سينطلق مشروع الإمارات لاستكشاف المريخ "مسبار الأمل" في رحلته إلى المريخ من مركز تانيغاشيما للفضاء باليابان. وتم تخصيص نافذة الإطلاق التي تمتد لحوال 30 يوماً وتبدأ في 14 يوليو 2020 وتنتهي في 12 أغسطس 2020، لإتمام إطلاق المسبار بنجاح إلى المريخ. في يوم الإطلاق سيقلع "مسبار الأمل" على متن صاروخ ميتسوبيشي (إتش 2 إيه) نحو الشرق في مسار يمتد فوق المحيط الهادئ.
                  </p>
                  <p>
                  ومع تسارع الصاروخ مبتعداً عن سطح الأرض، يتم استهلاك معززات الصاروخ الصلبة، وبمجرد اختراق الصاروخ للغلاف الجوي سيتم التخلص من الجسم الانسيابي الذي يحمي "مسبار الأمل" من الغلاف الجوي.
                  </p>
                  <p>
                  بمجرد انتهاء المرحلة الأولى من عملية الإطلاق، سيتم التخلص من الصاروخ ووضع المسبار في مدار الأرض حتى تتم عملية الاصطفاف الدقيق مع المريخ وعندها تبدأ المرحلة الثانية من توجيه "مسبار الأمل" في الاتجاه الصحيح نحو الكوكب الأحمر، بسرعة تبلغ 11 كيلومتر في الثانية.
                  </p>
                </div>
            </div>  
                    
          </div>  
      </section>
    </main>
  </Layout>
)

export default LaunchPage
